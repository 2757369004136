import React, { useEffect, useState } from "react";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    cloudStorage,
    popup,
    mockTelegramEnv,
    parseInitData,
    retrieveLaunchParams,
    init,
    viewport,
    miniApp,
    themeParams,
} from "@telegram-apps/sdk";
import { on } from "@telegram-apps/bridge";
import { setGlobalMsg, setProjectId } from "../../redux/slices/windowSlice";
import { useLoginMutation, useRegisterMutation } from "../../redux/api";
import { useLocation } from "react-router-dom";
import { RootState } from "../../app/store";
// import { Skeleton } from "@mui/material";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import CryptoJS from "crypto-js";
import WelcomePage from "../../pages/WelcomePage";
import { useLoadPlanByHash } from "../../hooks/useLoadPlanByHash";

const Initializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showWelcomePage, setShowWelcomePage] = useState(false); // Состояние для отображения WelcomePage
    const [isRegistering, setIsRegistering] = useState(false); // Состояние для отслеживания процесса регистрации
    

    if (process.env.NODE_ENV === "development") {
        const initDataRaw = new URLSearchParams([
            [
                "user",
                JSON.stringify({
                    id: 5976624413,
                    first_name: "Andrew",
                    last_name: "Rogue",
                    username: "rogue",
                    language_code: "en",
                    is_premium: true,
                    allows_write_to_pm: true,
                }),
            ],
            [
                "hash",
                "89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31",
            ],
            ["auth_date", "1716922846"],
            ["start_param", ""],
            ["chat_type", "sender"],
            ["chat_instance", "8428209589180549439"],
        ]).toString();

        mockTelegramEnv({
            themeParams: {
                accentTextColor: "#6ab2f2",
                bgColor: "#17212b",
                buttonColor: "#5288c1",
                buttonTextColor: "#ffffff",
                destructiveTextColor: "#ec3942",
                headerBgColor: "#17212b",
                hintColor: "#708499",
                linkColor: "#6ab3f3",
                secondaryBgColor: "#232e3c",
                sectionBgColor: "#17212b",
                sectionHeaderTextColor: "#6ab3f3",
                subtitleTextColor: "#708499",
                textColor: "#f5f5f5",
            },
            initData: parseInitData(initDataRaw),
            initDataRaw,
            version: "7.2",
            platform: "tdesktop",
        });
    }

    const dispatch = useAppDispatch();
    const location = useLocation();
    const { projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const { initDataRaw, initData } = retrieveLaunchParams();
    const { loadPlanByHash, isLoading: isLoadingPlanByHash, data: availablePlanByHash, isError: iseErrorPlanByHash } = useLoadPlanByHash(projectId, initData.startParam);
    const [
        login,
        { data: loginData, isLoading: isLoadingLogin, isError: isErrorLogin },
    ] = useLoginMutation();
    const [
        register,
        {
            data: registerData,
            isLoading: isLoadingRegister,
            isError: isErrorRegister,
        },
    ] = useRegisterMutation();
    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        const readProjectId = location.pathname.split("/")[1];
        if (readProjectId) {
            dispatch(setProjectId(readProjectId));
        }
    }, [location.pathname, dispatch]);

    const Alert = ({ title, text }) => {
        popup.open({
            title: title,
            message: text,
            buttons: [{ id: "my-id", type: "default", text: "Хорошо" }],
        });
    };

    const handleRegistration = async () => {
        setIsRegistering(true); // Устанавливаем состояние загрузки
        const response = await register({
            data: initDataRaw,
            project_id: projectId,
        });
        setIsRegistering(false); // Сбрасываем состояние загрузки
        if (
            response.error &&
            "status" in response.error &&
            response.error.status === 422
        ) {
            setLoginError(true);
            Alert({
                title: "Произошла ошибка",
                text: "Возникла проблема при регистрации аккаунта, обратитесь в поддержку Bot4Chat.",
            });
        } else if (response.data) {
            if (process.env.NODE_ENV === "development") {
                localStorage.setItem(
                    "b4chat-password-hash",
                    response.data.password
                );
            } else {
                await cloudStorage.setItem(
                    "b4chat-password-hash",
                    response.data.password
                );
            }
            setShowWelcomePage(false); // Скрыть WelcomePage после успешной регистрации
        }
    };

    useEffect(() => {
        const attemptLogin = async (password: string) => {
            const response = await login({
                telegram_id: initData.user.id,
                password,
                project_id: projectId,
            });
            if (
                response.error &&
                "status" in response.error &&
                response.error.status === 422
            ) {
                handleRegistration(); // Выполнить регистрацию при ошибке 422
            } else if (
                response.error &&
                "status" in response.error &&
                response.error.status === 500
            ) {
                dispatch(
                    setGlobalMsg("Бот временно недоступен, попробуйте позже.")
                ); // Отобразить глобальное сообщение
            } else if (response.data) {
                // dispatch(setPrimaryData(response.data));
            }
        };

        if (projectId && !loginError) {
            if (process.env.NODE_ENV === "development") {
                const password = localStorage.getItem("b4chat-password-hash");
                if (password) {
                    attemptLogin(password);
                } else {
                    setShowWelcomePage(true); // Показать WelcomePage, если пользователь не зарегистрирован
                }
            } else {
                cloudStorage
                    .getItem("b4chat-password-hash")
                    .then((password) => {
                        if (password) {
                            attemptLogin(password);
                        } else {
                            setShowWelcomePage(true); // Показать WelcomePage, если пользователь не зарегистрирован
                        }
                    });
            }
        }
    }, [projectId, login, register, initData.user.id, loginError]);

    useEffect(() => {
        if(initData.startParam && (loginData || registerData)) {
            loadPlanByHash();
        }
    }, [loginData, registerData, initData.startParam, loadPlanByHash]);

    const listener = (payload) => {
        document.documentElement.style.setProperty(
            "--tg-viewport-stable-height",
            `${payload.height}px`
        );
    };

    on("viewport_changed", listener);

    init();

    const handleStartClick = () => {
        handleRegistration();
    };

    if (showWelcomePage) {
        return (
            <WelcomePage
                onStartClick={handleStartClick}
                isLoading={isRegistering}
            />
        ); 
    }

    if (
        (initData.startParam && !availablePlanByHash) ||
        isLoadingPlanByHash ||
        isLoadingLogin ||
        isLoadingRegister ||
        isErrorRegister ||
        isErrorLogin ||
        (!loginData && !registerData)
    ) {
        return (
            <div className="loader">
                <div className="loader_container">
                    <div className="loader_wrapper">
                        <SkeletonTheme
                            baseColor="var(--theme-button-bg)"
                            highlightColor="var(--theme-bg)">
                            <Skeleton
                                height={20}
                                style={{ marginTop: "30px" }}
                            />
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                            <Skeleton
                                width="100%"
                                height={40}
                                style={{ marginTop: "40px" }}
                            />
                            <Skeleton
                                width="100%"
                                height={40}
                                style={{ marginTop: "5px" }}
                            />
                            <Skeleton
                                width="100%"
                                height={40}
                                style={{ marginTop: "5px" }}
                            />
                            <Skeleton
                                width="100%"
                                height={40}
                                style={{ marginTop: "5px" }}
                            />
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        );
    }



    return <>{children}</>;
};

export default Initializer;
