import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import SubscriptionButton from "../../components/SubscriptionButton";
import { changeStep, navigateToPage, setSelectedMySubscription } from "../../redux/slices/windowSlice";
import Localized from "../../Localized";
import minusRedDark from "../../img/icons/dark/minus-red.svg";
import minusRedLight from "../../img/icons/light/minus-red.svg";
import linkPinkDark from "../../img/icons/dark/link-pink.svg";
import linkPinkLight from "../../img/icons/light/link-pink.svg";

import likeDark from "../../img/icons/dark/like-green.svg";
import likeLight from "../../img/icons/light/like.svg";
import addNew from "../../img/icons/dark/add-new.svg";
import Button from "../../components/Button";
import { openTelegramLink, shareURL, popup } from "@telegram-apps/sdk";
import ListButtons from "../../components/ListButtons";
import defaultIcon from "../../img/icons/dark/image.svg";
import { useLoadPaymentMethods } from "../../hooks/useLoadPaymentMethods";
import { format } from "date-fns";
import { useAcceptPropositionMutation, useCancelSubscriptionMutation, useRenewSubscriptionMutation } from "../../redux/api";
import { useParams } from "react-router-dom";
import { useLoadMySubscriptions } from "../../hooks/useLoadMySubscriptions";

const SubscriptionInfo = () => {
    const dispatch = useAppDispatch();
    const { subscription_id } = useParams<{ subscription_id: string }>();
    
    const [
        cancelSubscription,
        { isLoading: isLoadingCancelSubscription}
    ] = useCancelSubscriptionMutation();
    const [
        acceptProposition,
        { isLoading: isLoadingAcceptProposition }
    ] = useAcceptPropositionMutation();
    const [
        renewSubscription,
        { isLoading: isLoadingRenewSubscription }
    ] = useRenewSubscriptionMutation();
    const { selectedMySubscription, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadMySubscriptions, isLoading: isLoadingMySubscriptions, data: mySubscriptions, isError: isErrorMySubscriptions } = useLoadMySubscriptions(projectId);
    
    useEffect(() => {
        if (subscription_id) {
            dispatch(navigateToPage(`my_subscriptions/${subscription_id}`));
            
            loadMySubscriptions();
        }
    }, []);

    useEffect(() => {
        if (subscription_id && mySubscriptions) {
            const selectedSubscription = mySubscriptions.active.find(sub => sub.id === subscription_id) || mySubscriptions.inactive.find(sub => sub.id === subscription_id);
            if (selectedSubscription) {
                dispatch(setSelectedMySubscription(selectedSubscription));
                dispatch(changeStep("subscription_info"));
            }
        }
    }, [subscription_id, mySubscriptions, dispatch]);

    const handlerCancelSubscription = async ({
        subscription_id,
        project_id,
        step = 0,
    }) => {
        const response = await cancelSubscription({
            project_id: project_id,
            subscription_id: subscription_id,
            step: step,
        });

        if (response.data.status === "proposition") {
            const { proposition } = response.data;
            const buttonId = await popup.open({
                title: "Точно отменяем?",
                message: proposition.text,
                buttons: [
                    {
                        id: "cancel",
                        type: "destructive",
                        text: "Нет, всё равно отменить.",
                    },
                    {
                        id: "accept",
                        type: "default",
                        text: `Остаюсь за ${proposition.price.amount} ${proposition.price.currency}/${proposition.price.interval} ${proposition.price.interval_unit}`,
                    },
                ],
            });

            if (buttonId === "cancel") {
                handlerCancelSubscription({
                    subscription_id,
                    project_id,
                    step: step + 1,
                });
            } else {

                acceptProposition({project_id: projectId, subscription_id: selectedMySubscription.id, step: step + 1}).unwrap().then(() => {
                    popup.open({
                        title: "Подтвреждение",
                        message: "Подписка успешно изменена.",
                        buttons: [
                            { id: "success-ok", type: "default", text: "Хорошо" }
                        ],
                    });
                }).catch((error) => {
                    popup.open({
                        title: "Ошибка",
                        message: error.data.error.error_msg,
                        buttons: [
                            { id: "error-ok", type: "default", text: "Хорошо" }
                        ],
                    });
                });
            }
        } else if (response.data.status === "success") {
            await popup.open({
                title: "Подписка отменена",
                message: response.data.message,
                buttons: [{ id: "ok", type: "default", text: "ОК" }],
            });
        }
    };

    const handlerRenewSubscription = async ({
        subscription_id,
        project_id,
    }) => {
        const response = await renewSubscription({
            project_id: project_id,
            subscription_id: subscription_id
        });

        if (response.data.status === "success") {
            await popup.open({
                title: "Подписка возобновлена",
                message: response.data.message,
                buttons: [{ id: "ok", type: "default", text: "ОК" }],
            });
        }
    };

    const formatPrice = (price) => {
        const { amount, currency, interval, interval_unit } = price;
        let intervalText = "";

        switch (interval_unit) {
            case "hour":
                intervalText = interval === 1 ? "час" : "часа";
                break;
            case "day":
                intervalText = interval === 1 ? "день" : "дня";
                break;
            case "week":
                intervalText = interval === 1 ? "неделя" : "недели";
                break;
            case "month":
                intervalText = interval === 1 ? "месяц" : "месяца";
                break;
            case "year":
                intervalText = interval === 1 ? "год" : "года";
                break;
            default:
                intervalText = interval_unit;
                break;
        }

        return `${amount} ${currency} / каждые ${interval} ${intervalText}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "dd.MM.yyyy в HH:mm");
    };

    const getRenewInformation = (billingInformation, status) => {
        const { renew_at, valid_until } = billingInformation;

        if (renew_at === null && status === "canceled") {
            return (
                "Бот автоматически удалит Вас из канала " +
                formatDate(valid_until) +
                "."
            );
        } else if (renew_at === null && status === "active") {
            return (
                "Состоится " + formatDate(valid_until) + "."
            );
        } else if (
            renew_at !== null &&
            (status === "active" || status === "processing")
        ) {
            return "Следующее списание " + formatDate(renew_at) + ".";
        } else if (
            status === "inactive"
        ) {
            return "Срок действия подписки истёк " + formatDate(valid_until) + ".";
        }  else {
            return "";
        }
    };

    if(!selectedMySubscription) {
        return null;
    }

    return (
        <div className="subscription-new">
            <div className="subscription-new_container">
                <div className="subscription-new_wrapper">
                    <div className="subscription-new_main-block">
                        <div className="subscription-new_main-block_information">
                            <div className="subscription-new_main-block_information_icon">
                                <img
                                    src=""
                                    alt=""
                                    className="subscription-new_main-block_information_icon_img"
                                />
                                <img
                                    src={defaultIcon}
                                    alt="Image"
                                    className="subscription-info_main-block_information_icon_img-default"
                                />
                            </div>
                            <div className="subscription-new_main-block_information_details">
                                <span className="subscription-new_main-block_information_details_title">
                                    {selectedMySubscription.title}
                                </span>
                                <span className="subscription-new_main-block_information_details_plan">
                                    {selectedMySubscription.title_friendly}
                                </span>
                            </div>
                        </div>
                        {/* <div className="subscription-new_main-block_plan-details">
                            <span className="subscription-new_main-block_plan-details_price">
                                {formatPrice(selectedMySubscription.price)}
                            </span>
                            <span className="subscription-new_main-block_plan-details_renew-information">
                                {getRenewInformation(selectedMySubscription.billing_information, selectedMySubscription.status)}
                            </span>
                        </div> */}
                        <div className="subscription-info_main-block_plan-details">
                            {selectedMySubscription.is_trial ? (
                                selectedMySubscription.trial_price?.amount ===
                                0 ? (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            Бесплатный пробный период, далее{" "}
                                            {
                                                selectedMySubscription.price
                                                    .amount
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .currency
                                            }{" "}
                                            /{" "}
                                            {
                                                selectedMySubscription.price
                                                    .interval
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .interval_unit
                                            }
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {getRenewInformation(
                                                selectedMySubscription.billing_information,
                                                selectedMySubscription.status
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">
                                            Пробный период за{" "}
                                            {
                                                selectedMySubscription
                                                    .trial_price?.amount
                                            }{" "}
                                            {
                                                selectedMySubscription
                                                    .trial_price?.currency
                                            }
                                            , далее{" "}
                                            {
                                                selectedMySubscription.price
                                                    .amount
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .currency
                                            }{" "}
                                            /{" "}
                                            {
                                                selectedMySubscription.price
                                                    .interval
                                            }{" "}
                                            {
                                                selectedMySubscription.price
                                                    .interval_unit
                                            }
                                        </span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">
                                            {getRenewInformation(
                                                selectedMySubscription.billing_information,
                                                selectedMySubscription.status
                                            )}
                                        </span>
                                    </>
                                )
                            ) : (
                                <>
                                    <span className="subscription-info_main-block_plan-details_price">
                                        {selectedMySubscription.price.amount}{" "}
                                        {selectedMySubscription.price.currency}{" "}
                                        /{" "}
                                        {selectedMySubscription.price.interval}{" "}
                                        {
                                            selectedMySubscription.price
                                                .interval_unit
                                        }
                                    </span>
                                    <span className="subscription-info_main-block_plan-details_renew-information">
                                        {getRenewInformation(
                                            selectedMySubscription.billing_information,
                                            selectedMySubscription.status
                                        )}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    <ListButtons sx={{ mb: 20 }}>
                        {selectedMySubscription.status != "inactive" && selectedMySubscription.group_invite_links.map(
                            (link, index) => (
                                <Button
                                    key={index}
                                    title={`Перейти в группу (${link.group_title})`}
                                    icon={linkPinkLight}
                                    action={() => {
                                        openTelegramLink(
                                            link.group_invite_link
                                        );
                                    }}
                                    bgColor="#DE8BEB"
                                />
                            )
                        )}
                        <Button
                            title="Посоветовать проект другу"
                            icon={likeLight}
                            action={() => {
                                shareURL(
                                    "https://t.me/testqdenyawebbot",
                                    "Рекомендую проект!"
                                );
                            }}
                            bgColor="#5856D6"
                        />
                    </ListButtons>
                    <ListButtons>
                        {["active", "processing"].includes(selectedMySubscription.status) ? (
                            <Button
                                title={Localized.CancelSubscription}
                                icon={minusRedLight}
                                bgColor="#BD293D"
                                action={() =>
                                    handlerCancelSubscription({
                                        project_id: projectId,
                                        subscription_id:
                                            selectedMySubscription.id,
                                        step: 0,
                                    })
                                }
                                isLoading={isLoadingCancelSubscription || isLoadingAcceptProposition}
                            />
                        ) : (
                            selectedMySubscription.on_renew && (
                                <Button
                                    title={
                                        Localized.RenewSubscription +
                                        ` • ${selectedMySubscription.on_renew.price.amount} ${selectedMySubscription.on_renew.price.currency} / ${selectedMySubscription.on_renew.price.interval} ${selectedMySubscription.on_renew.price.interval_unit}`
                                    }
                                    icon={addNew}
                                    bgColor="#34C759"
                                    action={() =>
                                        handlerRenewSubscription({
                                            project_id: projectId,
                                            subscription_id:
                                                selectedMySubscription.id,
                                        })
                                    }
                                    isLoading={isLoadingRenewSubscription}
                                />
                            )
                        )}
                    </ListButtons>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInfo;