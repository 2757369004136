import React, { useState, useEffect, useCallback } from "react";
import './style.scss';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useNavigate } from "react-router-dom";
import {
    changeStep,
    navigateToPage,
    pushRoute,
    setAnimationDirection,
} from "../../redux/slices/windowSlice";
import paymentSettingsIconCard from '../../img/icons/payment-settings-card.svg';
import ukassaLogo from '../../img/icons/ukassa.svg';
import bepaidLogo from '../../img/icons/bepaid.svg';
import stripeLogo from '../../img/icons/stripe.svg';
import Button from "../../components/Button";
import Localized from "../../Localized";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import ListButtons from "../../components/ListButtons";
import { useDeleteSavedCardMutation, useGetPaymentMethodsQuery } from "../../redux/api";
import { popup } from "@telegram-apps/sdk";
import { useLoadPaymentMethods } from "../../hooks/useLoadPaymentMethods";

const PaymentSettngs = () => {
    const dispatch = useAppDispatch();

    const { savedCards, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadPaymentMethods, isLoading, data, isError } = useLoadPaymentMethods(projectId);
    const [deleteSavedCard, {
        isLoading: isDeleting,
        isError: isErrorWithDeleteCard,
    }] = useDeleteSavedCardMutation();

    useEffect(() => {
        if (!isLoading && data && !isError) {
            dispatch(navigateToPage("add_new_card"));
        } 
    }, [data, isError, isLoading]);

    const getProviderLogo = (provider: string) => {
        var logo = null;
        switch (provider) {
            case "ukassa":
                logo = ukassaLogo;
                break;
            case "bepaid":
                logo = bepaidLogo;
                break;
            case "stripe":
                logo = stripeLogo;
                break;
            default:
                return null;
        }

        return <img src={logo} alt={provider} className="payment-settings_card-information_provider_img" />;
    }

    const RemoveSavedCard = (id: string) => {
        popup
            .open({
                title: "Подтверждение",
                message: "Вы действительно хотите удалить карту из системы? Это действие нельзя будет отменить.",
                buttons: [
                    { id: "my-id", type: "destructive", text: "Да, удалить карту" },
                    { id: "my-id-2", type: "default", text: "Отменить" }
                ],
            })
            .then((buttonId) => {
                if (buttonId === "my-id") {
                    deleteSavedCard({project_id: projectId, card_id: id}).unwrap().then(() => {
                        popup.open({
                            title: "Подтвреждение",
                            message: "Карта успешно удалена из системы.",
                            buttons: [
                                { id: "success-ok", type: "default", text: "Хорошо" }
                            ],
                        });
                        console.log(`Card with UID ${id} has been deleted.`);
                    }).catch((error) => {
                        popup.open({
                            title: "Ошибка",
                            message: error.data.error.error_msg,
                            buttons: [
                                { id: "error-ok", type: "default", text: "Хорошо" }
                            ],
                        });
                        console.error(`Failed to delete card with UID ${id}:`, error.data.error.error_msg);
                    });
                } else {
                    console.log("User canceled the deletion.");
                }
            });
    };

    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                <div className="payment-settings_wrapper">
                    {
                        savedCards.map((card, index) => {
                            return (
                                <div className="payment-settings_card-information" key={index}>
                                    <div className="payment-settings_card-information_details">
                                        <div className="payment-settings_card-information_details_icon">
                                            <img src={paymentSettingsIconCard} alt="Card" className="payment-settings_card-information_details_icon_img" />
                                        </div>
                                        <span className="payment-settings_card-information_details_title">
                                            Карта {card.brand ? card.brand : "Unknown brand"} {card.country ? `(${card.country})` : ""}
                                        </span>
                                        <span className="payment-settings_card-information_details_number">
                                            •••• •••• •••• {card.last_4 ? card.last_4 : "••••"} 
                                        </span>
                                    </div>
                                    <div className="payment-settings_card-information_provider">
                                        {getProviderLogo(card.provider)}
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        savedCards.length === 0 && (
                            <span className="payment-settings_non-card">К Вашему аккаунту ещё не привязана карта.</span>
                        )
                    }
                    
                    <ListButtons sx={{ mt: 20 }}>
                        <Button
                            title="Привязать новую карту"
                            icon={creditCardDark}
                            action={loadPaymentMethods}
                            bgColor="#29B6BD"
                            isLoading={isLoading}
                        />
                        {
                            savedCards.length > 0 && (
                                <Button
                                    title="Удалить карту из системы"
                                    icon={creditCardDark}
                                    action={() => RemoveSavedCard(savedCards[0].id)}
                                    bgColor="#BD293D"
                                    isLoading={isDeleting}
                                />
                            )
                        }
                    </ListButtons>
                </div>
            </div>
        </div>
    );
};

export default PaymentSettngs;