import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import Localized from "../../Localized";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
    changeStep,
    navigateToPage,
    pushRoute,
    setAnimationDirection,
    setSelectedMySubscription,
} from "../../redux/slices/windowSlice";

import SubscriptionButton from "../../components/SubscriptionButton";
import ListButtons from "../../components/ListButtons";
import { RootState } from "../../app/store";
import { Subscription } from "../../redux/types/window";
import lottie from "../../assets/output.json";
import { useLoadAvailablePlans } from "../../hooks/useLoadAvailablePlans";
import { CircularProgress } from "@mui/material";

const AvailableSubscriptions = () => {
    const dispatch = useAppDispatch();

    const { mySubscriptions, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadAvailablePlans, isLoading: isLoadingAvailablePlans, data: availablePlans, isError: iseErrorAvailablePlans } = useLoadAvailablePlans(projectId);

    useEffect(() => {
        if (!isLoadingAvailablePlans && availablePlans && !iseErrorAvailablePlans) {
            dispatch(navigateToPage("available_subscriptions"));
        } 
    }, [availablePlans, iseErrorAvailablePlans, isLoadingAvailablePlans]);

    const openMySubscriptions = (SelectedSubscription: Subscription) => {
        dispatch(setSelectedMySubscription(SelectedSubscription));
        dispatch(navigateToPage("subscription_info"));
    };

    const getDateTypeAndValue = (subscription) => {
        if (subscription.billing_information.renew_at) {
            return {
                dateType: "renew",
                dateValue: subscription.billing_information.renew_at,
            };
        } else if (
            subscription.billing_information.renew_at === null &&
            subscription.status === "canceled"
        ) {
            return {
                dateType: "expire",
                dateValue: subscription.billing_information.valid_until,
            };
        } else if (subscription.status === "inactive") {
            return {
                dateType: "expired",
                dateValue: subscription.billing_information.valid_until,
            };
        }
        return { dateType: "takeplace", dateValue: null };
    };

    return (
        <div className="my-subscriptions">
            <div className="my-subscriptions_container">
                <div className="my-subscriptions_wrapper">
                    {mySubscriptions.active.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                            <span className="my-subscriptions_text">
                                {Localized.ActiveSubscription}
                            </span>
                            <ListButtons>
                                {mySubscriptions.active.map((subscription) => {
                                    const { dateType, dateValue } =
                                        getDateTypeAndValue(subscription);
                                    return (
                                        <SubscriptionButton
                                            key={subscription.id}
                                            title={subscription.title}
                                            dateType={dateType}
                                            dateValue={dateValue}
                                            price={`${subscription.price.amount} ${subscription.price.currency}`}
                                            image=""
                                            action={() =>
                                                openMySubscriptions(
                                                    subscription
                                                )
                                            }
                                        />
                                    );
                                })}
                            </ListButtons>
                        </div>
                    )}

                    {mySubscriptions.inactive.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                            <span className="my-subscriptions_text">
                                {Localized.ExpiredSubscription}
                            </span>
                            <ListButtons>
                                {mySubscriptions.inactive.map(
                                    (subscription) => {
                                        const { dateType, dateValue } =
                                            getDateTypeAndValue(subscription);
                                        return (
                                            <SubscriptionButton
                                                key={subscription.id}
                                                title={subscription.title}
                                                dateType={dateType}
                                                dateValue={dateValue}
                                                price={`${subscription.price.amount} ${subscription.price.currency}`}
                                                image=""
                                                action={() =>
                                                    openMySubscriptions(
                                                        subscription
                                                    )
                                                }
                                            />
                                        );
                                    }
                                )}
                            </ListButtons>
                        </div>
                    )}

                    {mySubscriptions.active.length === 0 && mySubscriptions.inactive.length == 0  && (
                        <div className="my-subscriptions_empty">
                            <div className="my-subscriptions_empty_lottie">
                                <Lottie
                                    animationData={lottie}
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>

                            <span className="my-subscriptions_empty_text">
                                {Localized.NoActiveSubscriptions}
                            </span>
                            <div className="my-subscriptions_empty_button" onClick={() => loadAvailablePlans()}>
                                <div className="my-subscriptions_empty_button_wrapper">
                                    {isLoadingAvailablePlans ? <CircularProgress size="20px" thickness={6} style={{ color: 'white' }} /> : "Перейти к оформлению "} 
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AvailableSubscriptions;
