import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { navigateToPage, needSalute, popRoute } from "../../redux/slices/windowSlice";
import { popup } from "@telegram-apps/sdk";

const CheckoutForm = ({ token, public_key }) => {
    const stripePromise = loadStripe(public_key);
    const dispatch = useAppDispatch();
    
    const { projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const BepaidAlert = ({title, text}) => {
        popup
            .open({
                title: title,
                message: text,
                buttons: [
                    { id: "my-id", type: "default", text: "Хорошо" },
                ],
            })
    };

    const { loadSavedCards, isLoading, data, isError } = useLoadSavedCards(projectId);

    useEffect(() => {
        if (!isLoading && data && !isError) {
            BepaidAlert({
                title: "Ура!",
                text: "Карта была успешно добавлена в Ваш аккаунт. Теперь Вы можете оплачивать и оформлять подписки на проекты.",
            });
            dispatch(needSalute());
            dispatch(popRoute(2));
        } 
    }, [data, isError, isLoading]);
    

    return (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{
            clientSecret: token,
            onComplete: loadSavedCards
          }}>
            <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
    );
};

export default CheckoutForm;