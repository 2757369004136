import React, { useEffect, useState } from "react";
import "./style.scss";
import Button from "../../components/Button";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import ListButtons from "../../components/ListButtons";
import { useGetBepaidTokenMutation } from "../../redux/api";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { PaymentMethod } from "../../redux/types/window";
import { setIsSecondaryMenu } from "../../redux/slices/windowSlice";

const AddNewPaymentCardChoise = () => {
    const [selectedPaymentProviderId, setSelectedPaymentProviderId] = useState("");
    const [isRequestingToken, setIsRequestingToken] = useState(false);
    const [currentPaymentMethods, setCurrentPaymentMethods] = useState<PaymentMethod[]>([]);
    const [currentPaymentMethodsTitle, setCurrentPaymentMethodsTitle] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [getToken, {
        data: supplements,
        isLoading: isLoadingSupplements,
        isError: isErrorToken,
    }] = useGetBepaidTokenMutation();
    const { projectId, availablePaymentMethods, isSecondaryMenu } = useAppSelector((state: RootState) => state.windowSlice);

    useEffect(() => {
        if (!isSecondaryMenu) {
            setCurrentPaymentMethodsTitle("");
            setCurrentPaymentMethods(availablePaymentMethods);
        }
    }, [availablePaymentMethods, isSecondaryMenu]);
    
    const handleOpenSelectedPaymentProvider = (providerId: string, variants?: PaymentMethod[], title?: string) => {
        if (variants && variants.length > 0) {
            setCurrentPaymentMethods(variants);
            setCurrentPaymentMethodsTitle(title);
            dispatch(setIsSecondaryMenu(true));
        } else if (!isRequestingToken) {
            setSelectedPaymentProviderId(providerId);
            setIsRequestingToken(true);
            getToken({ provider_id: providerId, project_id: projectId }).finally(() => {
                setIsRequestingToken(false);
                dispatch(setIsSecondaryMenu(false));
            });
            
        }
    };

    return (
        <div className="payment-settings">
            <div className="payment-settings_container">
                {currentPaymentMethods.length && (
                    <div className="payment-settings_wrapper">
                        {
                            currentPaymentMethodsTitle && (
                                <span className="payment-settings_title">
                                    <p>Выбрано: </p>
                                    {currentPaymentMethodsTitle}
                                </span>
                            )
                        }
                        <ListButtons>
                            {currentPaymentMethods.map((method, index) => {
                                return (
                                    <Button
                                        key={index}
                                        title={method.title}
                                        icon={creditCardDark}
                                        action={() => handleOpenSelectedPaymentProvider(method.provider_id, method.variants, method.title)}
                                        isLoading={selectedPaymentProviderId === method.provider_id && !isErrorToken}
                                    />
                                )
                            })}
                        </ListButtons>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddNewPaymentCardChoise;