import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import './style.scss';
import { navigateToPage, setGlobalMsg } from "../../redux/slices/windowSlice";

const StatusWrapper = () => {
    const dispatch = useAppDispatch();
    const { serverUnavailable, globalMsg } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    useEffect(() => {
        if (globalMsg) {
            const timer = setTimeout(() => {
                dispatch(setGlobalMsg(""));
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [globalMsg, dispatch]);

    let msg = "";

    if (serverUnavailable) {
        msg = "Система временно недоступна. Попробуйте зайти позже.";
    } else if (globalMsg) {
        msg = globalMsg;
    }

    return (
        <div className="status-wrapper">
            <span className={`status-wrapper_text ${serverUnavailable || globalMsg ? 'shown' : ''}`}>{msg}</span>
        </div>
    );
};

export default StatusWrapper;