import React from "react";
import "./style.scss";

const NotAvailable = () => {
    return (
        <div className="main-page">
            <div className="main-page_container">
                <div className="main-page_wrapper">
                    <span className="main-page_welcometext">
                        Ваша версия Телеграм не поддерживает наш бот. Обновите приложение или воспользуйтесь веб-версией мессенджера.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NotAvailable;
