import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import SubscriptionButton from "../../components/SubscriptionButton";
import { changeStep, navigateToPage, needSalute } from "../../redux/slices/windowSlice";

import creditCardDark from "../../img/icons/dark/credit-card.svg";
import creditCardLight from "../../img/icons/light/credit-card.svg";
import { popup } from "@telegram-apps/sdk";
import linkPink from "../../img/icons/link-pink.svg";
import Button from "../../components/Button";
import SliderButton from "../../components/SliderButton";
import ListButtons from "../../components/ListButtons";
import defaultIcon from '../../img/icons/dark/image.svg';
import { useLoadPaymentMethods } from "../../hooks/useLoadPaymentMethods";
import { useSubscribeMutation } from "../../redux/api";

const SubscriptionNew = () => {
    const dispatch = useAppDispatch();
    const [subscribeState, setSubscribeState] = useState("ready");
    const { projectId, selectedPlan, savedCards, isSelectedPlanTrial } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadPaymentMethods, isLoading, data, isError } = useLoadPaymentMethods(projectId);
    const [subscribe, { data: subscribeData, isLoading: isLoadingSubscribe, isError: isErrorSubscribe, isSuccess: isSuccessSubscribe}] = useSubscribeMutation();

    useEffect(() => {
        if (!isLoading && data && !isError) {
            dispatch(navigateToPage("add_new_card"));
        } 
    }, [data, isError, isLoading]);

    const payAndSubscribe = () => {
        setSubscribeState("processing");
        subscribe({ project_id: projectId, plan_id: selectedPlan.id || selectedPlan.hash, is_trial: isSelectedPlanTrial }).unwrap().then(() => {
            setSubscribeState("success");
            dispatch(needSalute());
            popup.open({
                title: "Подписка успешно оформлена!",
                message: "Теперь Вы можете вступить в группу проекта.",
                buttons: [
                    { id: "accept", type: "default", text: `Закрыть` }
                ],
            });
        }).catch(() => {
            setSubscribeState("failure");
            setTimeout(() => {
                setSubscribeState("ready");
            }, 4000);
        });
    };
    
    return (
        <div className="subscription-info">
            <div className="subscription-info_container">
                <div className="subscription-info_wrapper">
                    <div className="subscription-info_main-block">
                        <div className="subscription-info_main-block_information">
                            <div className="subscription-info_main-block_information_icon">
                                <img src="" alt="" className="subscription-info_main-block_information_icon_img" />
                                <img src={defaultIcon} alt="Image" className="subscription-info_main-block_information_icon_img-default" />
                            </div>
                            <div className="subscription-info_main-block_information_details">
                                <span className="subscription-info_main-block_information_details_title">{selectedPlan.title}</span>
                                <span className="subscription-info_main-block_information_details_plan">{selectedPlan.title_friendly}</span>
                            </div>
                        </div>
                        <div className="subscription-info_main-block_plan-details">
                            {isSelectedPlanTrial ? (
                                selectedPlan.trial_price?.amount === 0 ? (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">Бесплатный пробный период, далее {selectedPlan.price.amount} {selectedPlan.price.currency} / {selectedPlan.price.interval} {selectedPlan.price.interval_unit}</span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">Через {selectedPlan.trial_price?.trial_interval} {selectedPlan.trial_price?.trial_interval_unit} оплата составит {selectedPlan.price.amount} {selectedPlan.price.currency}, если Вы не отмените подписку.</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="subscription-info_main-block_plan-details_price">Пробный период за {selectedPlan.trial_price?.amount} {selectedPlan.trial_price?.currency}, далее {selectedPlan.price.amount} {selectedPlan.price.currency} / {selectedPlan.price.interval} {selectedPlan.price.interval_unit}</span>
                                        <span className="subscription-info_main-block_plan-details_renew-information">Через {selectedPlan.trial_price?.trial_interval} {selectedPlan.trial_price?.trial_interval_unit} оплата составит {selectedPlan.price.amount} {selectedPlan.price.currency}, если Вы не отмените подписку.</span>
                                    </>
                                )
                            ) : (
                                <>
                                    <span className="subscription-info_main-block_plan-details_price">{selectedPlan.price.amount} {selectedPlan.price.currency} / {selectedPlan.price.interval} {selectedPlan.price.interval_unit}</span>
                                    <span className="subscription-info_main-block_plan-details_renew-information">Оплатите прямо сейчас и подписка будет активирована.</span>
                                </>
                            )}
                        </div>
                    </div>
                    {
                        savedCards.length > 0 ? (
                            <>
                                <SliderButton card={savedCards[0]} state={subscribeState} onPaymentStart={payAndSubscribe} type={isSelectedPlanTrial ? "trial": "subscribe"}/>
                                <span className="subscription-info_text">или</span>
                            </>
                        ) : (
                            <span className="subscription-info_text-warning">
                                {isSelectedPlanTrial ? "Для оформления пробного периода необходимо привязать банковскую карту." : "Необходимо привязать банковскую карту для оплаты."}
                            </span>
                        )
                    }
                    
                    <ListButtons>
                        <Button title="Привязать новую карту" icon={creditCardDark} action={loadPaymentMethods} isLoading={isLoading} />
                    </ListButtons>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionNew;
