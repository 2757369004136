import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../api";
import { WindowInfo } from "../types/window";

const initialState: WindowInfo = {
    stepCurrent: "main",
    loaderWindow: true,
    loaderButton: false,
    checkoutMsg: "",
    globalMsg: "",
    headerCutsom: "",
    isTestMode: false,
    animationDirection: "forward",
    serverUnavailable: false,
    routeStack: [
        {
            path: "",
            step: "main",
            swipe: "forward",
        },
    ],
    paymentStep: {
        type: "",
        token: "",
        public_key: "",
    },
    token: "",
    projectId: "",
    savedCards: [],
    availablePaymentMethods: [],
    isSecondaryMenu: false,
    availablePlans: [],
    mySubscriptions: {
        active: [],
        inactive: []
    },
    planByLinkInformation: null,
    selectedMySubscription: null,
    isSelectedPlanTrial: false,
    selectedPlan: null,
    saluteCounter: 0,
};

const windowInfo = createSlice({
    name: "window",
    initialState,
    reducers: {
        setIsSecondaryMenu(state, action: PayloadAction<boolean>) {
            state.isSecondaryMenu = action.payload;
        },
        changeStep(state, action: PayloadAction<string>) {
            state.stepCurrent = action.payload;
        },
        setAnimationDirection(state, action: PayloadAction<string>) {
            state.animationDirection = action.payload;
        },
        isMainLoader(state, action: PayloadAction<boolean>) {
            state.loaderWindow = action.payload;
        },
        isButtonLoader(state, action: PayloadAction<boolean>) {
            state.loaderButton = action.payload;
        },
        setCheckoutMsg(state, action: PayloadAction<string>) {
            state.checkoutMsg = action.payload;
        },
        setGlobalMsg(state, action: PayloadAction<string>) {
            state.globalMsg = action.payload;
        },
        pushRoute(state, action) {
            state.routeStack.push(action.payload);
            state.animationDirection = 'forward';
        },
        popRoute(state, action: PayloadAction<number | null>) {
            const count = action.payload ?? 1;
            for (let i = 0; i < count; i++) {
                if (state.routeStack.length > 0) {
                    state.routeStack.pop();
                } else {
                    break; 
                }
            }
            state.animationDirection = 'backward';
        },
        resetRouteStack(state) {
            state.routeStack = [];
        },
        setProjectId(state, action: PayloadAction<string>) {
            state.projectId = action.payload;
        },
        navigateToPage(state, action: PayloadAction<string>) {
            state.stepCurrent = action.payload;
            state.animationDirection = "forward";
            state.routeStack.push({
                step: action.payload,
                path: action.payload,
                swipe: "forward",
            });
        },
        setSelectedMySubscription(state, action: PayloadAction<any>) {
            state.selectedMySubscription = action.payload;
        },
        setSelectedAvailablePlan(state, action: PayloadAction<any>) {
            state.selectedPlan = action.payload;
        },
        setTrialMode(state, action: PayloadAction<boolean>) {
            state.isSelectedPlanTrial = action.payload;
        },
        needSalute(state) {
            state.saluteCounter++;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.getBepaidToken.matchFulfilled,
                (state, { payload }) => {
                    state.paymentStep = payload;
                    state.stepCurrent = payload.type;
                    state.animationDirection = "backward";
                    state.routeStack.push({
                        step: payload.type,
                        path: payload.type,
                        swipe: "forward",
                    });
                }
            )
            .addMatcher(
                api.endpoints.login.matchFulfilled,
                (state, { payload }) => {
                    state.token = payload.token;
                }
            )
            .addMatcher(
                api.endpoints.register.matchFulfilled,
                (state, { payload }) => {
                    state.token = payload.token;
                }
            )
            .addMatcher(
                api.endpoints.register.matchRejected,
                (state, action) => {
                    state.globalMsg = "Произошла ошибка при авторизации. Попробуйте чуть позже.";
                }
            )
            .addMatcher(
                api.endpoints.getSavedCards.matchFulfilled,
                (state, { payload }) => {
                    state.savedCards = payload;
                }
            )
            .addMatcher(
                api.endpoints.deleteSavedCard.matchFulfilled,
                (state, { payload }) => {
                    state.savedCards = payload;
                }
            )
            .addMatcher(
                api.endpoints.getPaymentMethods.matchFulfilled,
                (state, { payload }) => {
                    state.availablePaymentMethods = payload;
                }
            )
            .addMatcher(
                api.endpoints.getAvailablePlans.matchFulfilled,
                (state, { payload }) => {
                    state.availablePlans = payload;
                }
            )
            .addMatcher(
                api.endpoints.getPlanByLink.matchFulfilled,
                (state, { payload }) => {
                    console.log(payload);
                    switch (payload.type) {
                        case "same_groups":
                            state.planByLinkInformation = payload.details;
                            break;
                        case "available_plan":
                            state.selectedPlan = payload.available_plan;
                            state.stepCurrent = "description";
                            state.animationDirection = "forward";
                            state.routeStack = [
                                {
                                    path: "",
                                    step: "main",
                                    swipe: "forward",
                                },
                                {
                                    path: "description",
                                    step: "description",
                                    swipe: "forward",
                                }
                            ]
                            break;
                        case "already_subscribed":
                            state.selectedMySubscription = payload.subscription;
                            state.stepCurrent = "subscription_info";
                            state.animationDirection = "forward";
                            state.routeStack = [
                                {
                                    path: "",
                                    step: "main",
                                    swipe: "forward",
                                },
                                {
                                    path: "subscription_info",
                                    step: "subscription_info",
                                    swipe: "forward",
                                }
                            ]
                            break;
                    }
                }
            )
            .addMatcher(
                api.endpoints.getMySubscriptions.matchFulfilled,
                (state, { payload }) => {
                    state.mySubscriptions = payload;
                }
            )
            .addMatcher(
                api.endpoints.subscribe.matchFulfilled,
                (state, { payload }) => {
                    state.selectedMySubscription = payload.subscription;
                    state.stepCurrent = "subscription_info";
                    state.animationDirection = "forward";
                    state.routeStack = [
                        {
                            path: "",
                            step: "main",
                            swipe: "forward",
                        },
                        {
                            path: "subscription_info",
                            step: "subscription_info",
                            swipe: "forward",
                        }
                    ]
                }
            )
            .addMatcher(
                api.endpoints.cancelSubscription.matchFulfilled,
                (state, { payload }) => {
                    if(payload.status === "success") {
                        state.selectedMySubscription = payload.subscription;
                    }
                }
            )
            .addMatcher(
                api.endpoints.subscribe.matchRejected,
                (state, action) => {
                    switch (action.payload.data['error']['error_code']) {
                        case 402:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        case 403:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        default:
                            state.globalMsg = "Произошла ошибка. Попробуйте чуть позже.";
                            break;
                    }
                   
                }
            )
            .addMatcher(
                api.endpoints.renewSubscription.matchFulfilled,
                (state, { payload }) => {
                    state.selectedMySubscription = payload.subscription;
                    state.stepCurrent = "subscription_info";
                    state.animationDirection = "forward";
                    state.routeStack = [
                        {
                            path: "",
                            step: "main",
                            swipe: "forward",
                        },
                        {
                            path: "subscription_info",
                            step: "subscription_info",
                            swipe: "forward",
                        }
                    ]
                }
            )
            .addMatcher(
                api.endpoints.renewSubscription.matchRejected,
                (state, action) => {
                    switch (action.payload.data['error']['error_code']) {
                        case 402:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        case 403:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        default:
                            state.globalMsg = "Произошла ошибка. Попробуйте чуть позже.";
                            break;
                    }
                   
                }
            )
            .addMatcher(
                api.endpoints.acceptProposition.matchFulfilled,
                (state, { payload }) => {
                    state.selectedMySubscription = payload.subscription;
                    state.stepCurrent = "subscription_info";
                    state.animationDirection = "forward";
                    state.routeStack = [
                        {
                            path: "",
                            step: "main",
                            swipe: "forward",
                        },
                        {
                            path: "subscription_info",
                            step: "subscription_info",
                            swipe: "forward",
                        }
                    ]
                }
            )
            .addMatcher(
                api.endpoints.acceptProposition.matchRejected,
                (state, action) => {
                    switch (action.payload.data['error']['error_code']) {
                        case 402:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        case 403:
                            state.globalMsg = action.payload.data['error']['error_msg'];
                            break;
                        default:
                            state.globalMsg = "Произошла ошибка. Попробуйте чуть позже.";
                            break;
                    }
                   
                }
            )
            .addMatcher(
                api.endpoints.getPaymentMethods.matchRejected,
                (state, action) => {
                    // console.log(action);
                }
            )
            .addMatcher(
                api.endpoints.getBepaidToken.matchRejected,
                (state, action) => {
                    // console.log(action);
                    // if (payload.status === 500) {
                        state.globalMsg = "Произошла ошибка. Попробуйте чуть позже.";
                    // }
                }
            )
            ;
    },
});

export const {
    changeStep,
    isMainLoader,
    isButtonLoader,
    setCheckoutMsg,
    setGlobalMsg,
    setAnimationDirection,
    pushRoute,
    popRoute,
    resetRouteStack,
    setProjectId,
    navigateToPage,
    setIsSecondaryMenu,
    setSelectedMySubscription,
    setSelectedAvailablePlan,
    setTrialMode,
    needSalute
} = windowInfo.actions;
export default windowInfo.reducer;
