import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import Localized from "../../Localized";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { popRoute, changeStep, setGlobalMsg, setIsSecondaryMenu } from "../../redux/slices/windowSlice";
import backIconDark from "../../img/icons/dark/back-arrow.svg";

const Header = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { WebApp } = window.Telegram;

    const { stepCurrent, headerCutsom, routeStack, projectId, isSecondaryMenu } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isAndroid = WebApp.platform === "android";
    const isVisibleBack = WebApp.BackButton.isVisible;

    let headerTitle = "";
    switch (stepCurrent) {
        case "main":
            headerTitle = headerCutsom || "";
            break;
        case "my_subscriptions":
            headerTitle = Localized.MySubscriptions;
            break;
        case "available_subscriptions":
            headerTitle = Localized.AvailableSubscription;
            break;
        case "subscription_info":
            headerTitle = "Информация о подписке";
            break;
        case "subscription_new":
            headerTitle = "Оформление подписки";
            break;
        case "payment_settings":
            headerTitle = "Настройки оплаты";
            break;
        case "add_new_card":
            headerTitle = "Привязать новую карту";
            break;

        case "stripe":
        case "bepaid":
            headerTitle = "Привязать новую карту";
            break; 

        case "description":
            headerTitle = "Оформление подписки";
            break; 

        case "welcome":
            headerTitle = "";
            break;

        default:
            headerTitle = "";
            break;
    }

    const handleBackButtonClick = () => {
        if(isSecondaryMenu) {
            dispatch(setIsSecondaryMenu(false));
        } else {
            if (routeStack.length > 0) {
                dispatch(popRoute()); 
            }
        }
        
    };

    useEffect(() => {
        if (routeStack.length > 0) {
            const previousRoute = routeStack[routeStack.length - 1];
            if (previousRoute) {
                dispatch(setGlobalMsg(""));
                dispatch(changeStep(previousRoute.step)); // Обновляем состояние шага
                navigate(`${projectId}/${previousRoute.path}`, {replace: true}); // Перемещаемся на предыдущий маршрут
            } else {
                console.error("Invalid previousRoute:", previousRoute);
            }
        }
    }, [routeStack, dispatch, navigate]);

    return (
        <div className="header">
            <div className="header_container">
                <div className="header_wrapper">
                    {routeStack.length > 1 && (isVisibleBack || isAndroid || process.env.NODE_ENV === 'development') ? (
                        <div
                            className="header_back"
                            onClick={handleBackButtonClick}>
                            <img
                                src={backIconDark}
                                alt="Back"
                                className="header_back_img"
                            />
                        </div>
                    ) : null}

                    <div className="header_title">
                        <span className="header_title_text">{headerTitle}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
