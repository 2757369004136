import React from "react";
import "./style.scss";

import adv1 from '../../img/icons/welcome/adv1.svg';
import adv2 from '../../img/icons/welcome/adv2.svg';
import adv3 from '../../img/icons/welcome/adv3.svg';
import { CircularProgress } from "@mui/material";

const WelcomePage: React.FC<{ onStartClick: () => void, isLoading: boolean }> = ({ onStartClick, isLoading }) => {
    return (
        <div className="welcome-page">
            <div className="welcome-page_container">
                <div className="welcome-page_wrapper">
                    <h1 className="welcome-page_title">
                        Бот для оплаты подписок в проектах "Терапия в лицах"
                    </h1>
                    <h4 className="welcome-page_project-title">
                        Центр профессиональной подготовки психологов «Терапия в
                        лицах»
                    </h4>
                    <div className="welcome-page_advantages">
                        <div className="welcome-page_advantages_item">
                            <div className="welcome-page_advantages_item_icon">
                                <img
                                    src={adv1}
                                    alt="advantages"
                                />
                            </div>
                            <div className="welcome-page_advantages_item_text-block">
                                <h3 className="welcome-page_advantages_item_title">
                                    Легкость оформления
                                </h3>
                                <p className="welcome-page_advantages_item_text">
                                    Интуитивно понятный интерфейс для оплаты и управления подписками
                                </p>
                            </div>
                        </div>
                        <div className="welcome-page_advantages_item">
                            <div className="welcome-page_advantages_item-icon">
                                <img
                                    src={adv2}
                                    alt="advantages"
                                />
                            </div>
                            <div className="welcome-page_advantages_item_text-block">
                                <h3 className="welcome-page_advantages_item_title">
                                    Простота оплаты
                                </h3>
                                <p className="welcome-page_advantages_item_text">
                                    Возможность оплаты любой картой, поддерживающей интернет-платежи
                                </p>
                            </div>
                        </div>
                        <div className="welcome-page_advantages_item">
                            <div className="welcome-page_advantages_item-icon">
                                <img
                                    src={adv3}
                                    alt="advantages"
                                />
                            </div>
                            <div className="welcome-page_advantages_item_text-block">
                                <h3 className="welcome-page_advantages_item_title">
                                    Удобство использования
                                </h3>
                                <p className="welcome-page_advantages_item_text">
                                    Управление подписками прямо из меню бота в Telegram
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="welcome-page_bottom">
                        <div className="welcome-page_bottom_button" onClick={onStartClick}>
                            <div className="welcome-page_bottom_button_wrapper">
                                {isLoading ? <CircularProgress size="20px" thickness={6} style={{ color: 'white' }} /> : "Начать"} 
                            </div>
                        </div>
                        <span className="welcome-page_bottom_text">
                            Нажимая “Начать”, вы принимаете<br/><a href="/offer-agreement" className="welcome-page_bottom_link">Договор оферты</a>
                        </span>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
};

export default WelcomePage;