import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./style.scss";
import ListButtons from "../../components/ListButtons";
import Button from "../../components/Button";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import addNew from "../../img/icons/dark/add-new.svg";
import fire from "../../img/icons/dark/fire.svg";
import { viewport } from '@telegram-apps/sdk';
import { navigateToPage, setTrialMode } from "../../redux/slices/windowSlice";
import { RootState } from "../../app/store";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";

const DescriptionProject = () => {
    const dispatch = useAppDispatch();
    const [moreText, setMoreText] = useState(false);
    const [isLoadingSubscribeBtn, SetLoadingSubscribeBtn] = useState(false);
    const [isLoadingTrialBtn, SetLoadingTrialBtn] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const containerRef = useRef(null);
    const descriptionRef = useRef(null);

    const { selectedPlan, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadSavedCards, isLoading: isLoadingSavedCards, data: savedCards, isError: isErrorSavedCards } = useLoadSavedCards(projectId);

    const toggleMoreText = () => {
        setMoreText(!moreText);
        initializeViewport();
    };

    const initializeViewport = () => {
        viewport.expand(); 
    };

    const selectTrialMode = () => {
        dispatch(setTrialMode(true));
        SetLoadingTrialBtn(true);
        loadSavedCards();
    }

    const selectNotTrialMode = () => {
        SetLoadingSubscribeBtn(true);
        loadSavedCards();
    }
    
    useEffect(() => {
        dispatch(setTrialMode(false));
    }, []);

    useEffect(() => {
        if (!isLoadingSavedCards && savedCards && !isErrorSavedCards) {
            dispatch(navigateToPage("subscription_new"));
            SetLoadingSubscribeBtn(false);
            SetLoadingTrialBtn(false);
        } 
    }, [savedCards, isErrorSavedCards, isLoadingSavedCards]);

    useEffect(() => {
        if (descriptionRef.current) {
            const height = descriptionRef.current.offsetHeight;
            setShowMoreButton(height > 75);
        }
    }, [selectedPlan.description_message]);

    return (
        <div
            className={`description-project ${moreText ? "expanded" : ""}`}
            ref={containerRef}>
            <div className="description-project_container">
                <div className="description-project_wrapper">
                    <span className="description-project_text">Описание проекта</span>
                    <div className="description-project_drop-down">
                        <span className="description-project_description" ref={descriptionRef}>
                            {selectedPlan.description_message}
                        </span>
                    </div>
                    {showMoreButton && (
                        <div
                            className="description-project_open-more"
                            onClick={toggleMoreText}>
                            <span className="description-project_open-more_text">
                                Показать полностью
                            </span>
                        </div>
                    )}
                    <ListButtons sx={{ mt: 40, mb: 40 }}>
                        <Button
                            title={`Оформить • ${selectedPlan.price.amount} ${selectedPlan.price.currency} / ${selectedPlan.price.interval} ${selectedPlan.price.interval_unit}`}
                            icon={addNew}
                            action={() => selectNotTrialMode()}
                            bgColor="#29B6BD"
                            isLoading={isLoadingSubscribeBtn}
                        />
                        {
                            selectedPlan.trial_price && (
                                <Button
                                    title={`Попробовать • ${selectedPlan.trial_price?.amount} ${selectedPlan.trial_price?.currency} / ${selectedPlan.trial_price?.trial_interval} ${selectedPlan.trial_price?.trial_interval_unit}`}
                                    icon={fire}
                                    action={() => selectTrialMode()}
                                    bgColor="#FFCC00"
                                    isLoading={isLoadingTrialBtn}
                                />
                            )
                        }
                    </ListButtons>
                </div>
            </div>
        </div>
    );
};

export default DescriptionProject;