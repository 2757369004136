import React, { useState, useEffect } from "react";
import "./style.scss";
import Button from "../../components/Button";
import Localized from "../../Localized";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { openTelegramLink, retrieveLaunchParams, popup } from "@telegram-apps/sdk";
import { navigateToPage } from "../../redux/slices/windowSlice";

import officeBagDark from "../../img/icons/dark/office-bag.svg";
import addDark from "../../img/icons/dark/add.svg";
import creditCardDark from "../../img/icons/dark/credit-card.svg";
import supportIconDark from "../../img/icons/dark/support.svg";
import ListButtons from "../../components/ListButtons";
import { useLoadSavedCards } from "../../hooks/useLoadSavedCards";
import { useLoadMySubscriptions } from "../../hooks/useLoadMySubscriptions";
import { useLoadAvailablePlans } from "../../hooks/useLoadAvailablePlans";
import { useSubscribeMutation } from "../../redux/api";

const MainScreen = () => {
    const dispatch = useAppDispatch();
    const { initDataRaw, initData } = retrieveLaunchParams();

    const { planByLinkInformation, projectId } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { loadSavedCards, isLoading: isLoadingSavedCards, data: savedCards, isError: isErrorSavedCards } = useLoadSavedCards(projectId);
    const { loadMySubscriptions, isLoading: isLoadingMySubscriptions, data: mySubscriptions, isError: isErrorMySubscriptions } = useLoadMySubscriptions(projectId);
    const { loadAvailablePlans, isLoading: isLoadingAvailablePlans, data: availablePlans, isError: iseErrorAvailablePlans } = useLoadAvailablePlans(projectId);
    const [subscribe, { data: subscribeData, isLoading: isLoadingSubscribe, isError: isErrorSubscribe, isSuccess: isSuccessSubscribe}] = useSubscribeMutation();

    useEffect(() => {
        if (!isLoadingSavedCards && savedCards && !isErrorSavedCards) {
            dispatch(navigateToPage("payment_settings"));
        } 
    }, [savedCards, isErrorSavedCards, isLoadingSavedCards]);

    useEffect(() => {
        if (!isLoadingMySubscriptions && mySubscriptions && !isErrorMySubscriptions) {
            dispatch(navigateToPage("my_subscriptions"));
        } 
    }, [mySubscriptions, isErrorMySubscriptions, isLoadingMySubscriptions]);

    useEffect(() => {
        if (!isLoadingAvailablePlans && availablePlans && !iseErrorAvailablePlans) {
            dispatch(navigateToPage("available_subscriptions"));
        } 
    }, [availablePlans, iseErrorAvailablePlans, isLoadingAvailablePlans]);

    useEffect(() => {
        const handlePopup = async () => {
            if (planByLinkInformation && planByLinkInformation.current_plan && planByLinkInformation.new_plan) {
                console.log("test");
                
                const buttonId = await popup.open({
                    title: "Точно меняем тариф?",
                    message: `У вас уже есть подписка ${planByLinkInformation.current_plan.title} по цене ${planByLinkInformation.current_plan.price.amount} ${planByLinkInformation.current_plan.price.currency} / ${planByLinkInformation.current_plan.price.interval} ${planByLinkInformation.current_plan.price.interval_unit}. Вы уверены, что хотите сменить тариф на ${planByLinkInformation.new_plan.price.amount} ${planByLinkInformation.new_plan.price.currency} / ${planByLinkInformation.new_plan.price.interval} ${planByLinkInformation.new_plan.price.interval_unit}? Следующее списание произойдет ${planByLinkInformation.new_plan.billing_information.renew_at}.`,
                    buttons: [
                        {
                            id: "cancel",
                            type: "default",
                            text: "Нет, оставить прежний тариф.",
                        },
                        {
                            id: "accept",
                            type: "default",
                            text: `Да, сменить тариф.`,
                        },
                    ],
                });

                if (buttonId === "accept") {
                    subscribe({ project_id: projectId, plan_id: planByLinkInformation.new_plan.hash }).unwrap().then(() => {
                        popup.open({
                            title: "Тариф успешно изменен!",
                            message: "Вы можете дальше пользоваться подпиской.",
                            buttons: [
                                { id: "accept", type: "default", text: `Закрыть` }
                            ],
                        });
                    });
                }
            }
        };

        handlePopup();
    }, [planByLinkInformation]);

    return (
        <div className="main-page">
            <div className="main-page_container">
                <div className="main-page_wrapper">
                    <span className="main-page_welcometext">
                        🌸 Коллега, приветствуем тебя в Центре профессиональной
                        подготовки психологов «Терапия в лицах»!
                    </span>
                    <ListButtons>
                        <Button
                            title={Localized.MySubscriptions}
                            icon={officeBagDark}
                            action={loadMySubscriptions}
                            bgColor="#007AFF"
                            isLoading={isLoadingMySubscriptions}
                        />
                        <Button
                            title={Localized.AvailableSubscription}
                            icon={addDark}
                            action={loadAvailablePlans}
                            bgColor="#34C759"
                            isLoading={isLoadingAvailablePlans}
                        />
                        <Button
                            title={Localized.PaymentSettngs}
                            icon={creditCardDark}
                            action={loadSavedCards}
                            bgColor="#FF9F0A"
                            isLoading={isLoadingSavedCards}
                        />
                        <Button
                            title={Localized.ManagerContact}
                            icon={supportIconDark}
                            action={() => {
                                openTelegramLink("https://t.me/RinaZG");
                            }}
                            bgColor="#AF52DE"
                        />
                    </ListButtons>
                    <p className="main-page_dbg">ID: {initData.user.id}</p>
                </div>
            </div>
        </div>
    );
};

export default MainScreen;