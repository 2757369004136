import React, { useRef, useState } from "react";
import './style.scss';
import Localized from "../../Localized";
import { createRipple } from "../../utils";
import defaultIcon from '../../img/icons/dark/image.svg';
import { format } from 'date-fns';

const SubscriptionButton = ({ title, dateType, dateValue, price, image, action = null }) => {
    const buttonRef = useRef(null);
    const [rippleStyle, setRippleStyle] = useState({});
    const [isRippling, setIsRippling] = useState(false);

    const handleClick = (e) => {
        // createRipple(e, buttonRef, setRippleStyle, setIsRippling);
        if (action) action();
    };

    const formatDate = (dateString) => {
        if(dateString) {
            const date = new Date(dateString);
            return format(date, 'dd.MM.yyyy HH:mm');
        }
        return "";
    };

    var informationDate = "";

    switch (dateType) {
        case "renew":
            informationDate = Localized.WillRenew;
            break;
        case "expired":
            informationDate = "Истекла";
            break;
        case "expire":
            informationDate = Localized.WillExpire;
            break;
        case "takeplace":
            informationDate = Localized.Takeplace;
            break;
        case "renew_type":
            informationDate = "Оплата регулярная.";
            break;
        default:
            informationDate = "";
            break;
    }

    return (
        <div className="subscription-button" ref={buttonRef} onClick={handleClick}>
            {isRippling && <span className="ripple" style={rippleStyle} />}
            <div className="subscription-button_image">
                <img
                    src={image}
                    alt=""
                    className="subscription-button_image_img"
                />
                <img src={defaultIcon} alt="Image" className="subscription-button_image_img-default" />
            </div>
            <div className="subscription-button_information">
                <span className="subscription-button_information_title">
                    {title}
                </span>
                <span className="subscription-button_information_date">{informationDate + " " + formatDate(dateValue)}</span>
            </div>
            <div className="subscription-button_price">
                <span className="subscription-button_price_amount">{price}</span>
            </div>
        </div>
    );
};

export default SubscriptionButton;