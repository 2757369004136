import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import { applySx, createRipple } from "../../utils";
import { CircularProgress } from "@mui/material";

const Button = ({ icon, title, sx = {}, action = null, bgColor = "#8a8a90", isLoading = false }) => {
    const customStyle = applySx(sx);
    const buttonRef = useRef(null);
    const [rippleStyle, setRippleStyle] = useState({});
    const [isRippling, setIsRippling] = useState(false);

    const handleClick = (e) => {
        createRipple(e, buttonRef, setRippleStyle, setIsRippling);
        if (action) action();
    };

    return (
        <div
            ref={buttonRef}
            className="button"
            style={{
                ...customStyle,
            }}
            onClick={handleClick}
        >
            {(isRippling && isLoading) && <span className="ripple" style={rippleStyle} />}
            <div className="button_icon" style={{
                backgroundColor: bgColor,
            }}>
                {isLoading ? <CircularProgress size="15px" thickness={6} style={{ color: 'white' }} /> : <img src={icon} alt="" className="button_icon_img" />}
            </div>
            <span className="button_title">{title}</span>
        </div>
    );
};

export default Button;
